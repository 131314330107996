/**
 * Localstorage key.
 */
export const SettingKeyConstants = {
  EquipmentDetailExpandCollapse: 'EquipmentDetailExpandCollapse',
  EquipmentDetailDisplayAllFields: 'EquipmentDetailDisplayAllFields',
  AuthenTypes: 'AuthenTypes',
  AuthenticatedUserInfo: 'AuthenticatedUserInfo',
  AccessToken: 'AccessToken',
  Language: 'Language',
  RefreshToken: 'RefreshToken',
  CompanyId: 'CompanyId',
  UserEmail: 'UserEmail',
  FriendlyAlias: 'FriendlyAlias',
  InputAlias: 'InputAlias',
  InputUid: 'InputUid',
  InputUdpExpert: 'InputUdpExpert',
  AccountId: 'AccountId',
  LoggedUid: 'LoggedUid',
  ExpressEquipmentLicense: 'ExpressEquipmentLicense',
  ShowDebugLog: '__show_debug_log',
};
