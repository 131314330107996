import { DefaultButton, PanelType } from '@fluentui/react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../../common/ui/Button/PrimaryButton';
import { PanelWithScrollableBody } from '../../common/ui/PanelWithScrollableBody/PanelWithScrollableBody';
import { getAlertSetting } from '../../helpers/captured-event-alert.helper';
import { AlertSetting, KeysOfAlertSetting, SaveEventAlert } from '../../models/alert.model';
import { CompanyExpressContext } from '../../pages/Layout/MainLayout';
import { CapturedEventAlertService } from '../../services/captured-event-alert.service';
import { showDiscardChanges } from '../../services/modal.service';
import { ModalProps } from '../../types/modal.type';
import { isEqualSimple } from '../../utils/common.utils';
import { getLanguageId } from '../../utils/localStorage.utils';
import EditAlerts from './EditAlerts';

type Props = ModalProps;

const Alerts = ({ isOpen, onDismiss }: Props) => {
  const [translate] = useTranslation();
  const { info } = useContext(CompanyExpressContext);
  const { companyExpressSetup, contact } = info;
  const initialSetting = {
    isInspection: false,
    isOrderRecevied: false,
  } as AlertSetting;
  const [originSetting, setOriginSetting] = useState(initialSetting);
  const [alertSetting, setAlertSetting] = useState(initialSetting);

  const hasChanges = !isEqualSimple(alertSetting, originSetting);

  const closeModal = useCallback(() => {
    if (hasChanges) {
      showDiscardChanges({
        onDiscard: onDismiss,
      });
      return;
    }
    onDismiss && onDismiss();
  }, [onDismiss, hasChanges]);

  const handleToggle = (field: KeysOfAlertSetting, checked?: boolean | undefined) => {
    if (checked !== undefined) {
      setAlertSetting((prev) => {
        return {
          ...prev,
          [field]: checked,
        };
      });
    }
  };

  const handleSaveEventAlert = async () => {
    const data = {
      companyOwner: companyExpressSetup.companyId,
      email: contact.email,
      languageId: getLanguageId(),
      isInspection: alertSetting.isInspection,
      isOrderRecevied: alertSetting.isOrderRecevied,
    } as SaveEventAlert;

    const result = await CapturedEventAlertService.saveEventAlert(data);
    if (result === true) {
      onDismiss && onDismiss();
    }
  };

  useEffect(() => {
    const loadSetting = async () => {
      const result = await CapturedEventAlertService.getEventAlertByEmail(companyExpressSetup.companyId, contact.email, getLanguageId());
      if (result && result?.capturedEvents?.length > 0) {
        const setting = getAlertSetting(result?.capturedEvents);
        setOriginSetting(setting);
        setAlertSetting(setting);
      }
    };

    loadSetting();
  }, []);

  return (
    <PanelWithScrollableBody
      isOpen={isOpen}
      onDismiss={closeModal}
      title={translate('CommonResource.Alerts')}
      content={translate('CommonResource.SetupWeeklyEmailAlertsChangesRelatedEquipments')}
      panelProps={{ type: PanelType.smallFixedFar }}
      renderBody={() => <EditAlerts setting={alertSetting} handleToggle={handleToggle} />}
      renderFooter={() => (
        <>
          <PrimaryButton text={translate('CommonResource.Save')} onClick={handleSaveEventAlert} />
          <DefaultButton text={translate('CaptionResource.Caption53')} onClick={closeModal} />
        </>
      )}
    />
  );
};

export default Alerts;
