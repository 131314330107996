import { FilterControl } from '../../enums/filter-control.enum';

export class FieldFilter {
  fieldName: string = '';
  fieldValue: string = '';
  type?: number;
  options?: any[] = [];
}

export interface ISearchField {
  key: string;
  control: FilterControl;
  type: number;
  label: string;
  placeholder?: string;
  controlRef?: any;
  value?: any;
  options?: any;
  disabled?: boolean;
  panelTitle?: string;
  required?: boolean;
  errorMessage?: string;
  selectAllPermission?: boolean;
  maxNumberSelection?: number;
}

export class FilterControlRef {
  data: any;
  onClearData?: () => void;
  getFilter?: () => FieldFilter | undefined;
  getFilters?: () => FieldFilter[] | undefined;
}

export interface ISearchFieldProps {
  field: ISearchField;
  labelAdditionalInfo?: React.ReactNode;
  onSearch?: (params: any) => any;
  onRenderTitleCustom?: (params: any) => any;
  onInvokeApplyFilters?: () => void;
  onRenderHeaderContentCustom?: (params: any) => any;
  onRenderLabelCheckbox?: (item: any, props?: any, defaultRender?: (props?: any) => JSX.Element | null) => JSX.Element | null;
  onDataChanged?: () => void;
  dropDownPlaceHolder?: string;
  detailPanelRef?: React.RefObject<any>;
}
