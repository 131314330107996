
import { SettingKeyConstants } from '../constants/setting-keys.constants';
import { getSessionStorageItem } from '../utils/sessionStorage.utils';
const getErrorMessage = (value: any): string | undefined => {
  if (value?.Message) {
    return value.Message;
  }
  return undefined;
};

const isEmptyOrWhiteSpaces = (input: string): boolean => {
  if (input === undefined || input === null) {
    return true;
  } else {
    return input.match(/^ *$/) !== null;
  }
};

const CommonHelper = {
  userAccountId: getSessionStorageItem(SettingKeyConstants.AccountId),
  /*
   * Get error message from API response
   */
  getErrorMessage,
  isEmptyOrWhiteSpaces,
};

export default CommonHelper;
