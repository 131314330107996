import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import SignOut from '../components/SignOut/SignOut';
import { RoutingConstant } from '../constants/routing.constants';
import AuthCallback from '../pages/Authentication/AuthCallback';
import Authentication from '../pages/Authentication/Authentication';
import AuthenticationOIDC from '../pages/Authentication/AuthenticationOIDC';
import Document from '../pages/Document/Document';
import { Equipment } from '../pages/Equipment/Equipment';
import Home from '../pages/Home/Home';
import ContentLayout from '../pages/Layout/ContentLayout';
import Logout from '../pages/Logout/Logout';
import NotFound from '../pages/NotFound/NotFound';
import HomePreview from '../pages/Preview/Home/Home';
import Preview from '../pages/Preview/Preview';
import Unsubscribe from '../pages/Unsubscribe/Unsubscribe';
import { hasExpressEquipmentLicense } from '../services/authentication.service';
import { equipmentActions, equipmentSelector } from '../states/equipment/equipmentSlice';

const Routers = () => {
  const { hasEquipmentLicense } = useSelector(equipmentSelector);
  const dispatch = useDispatch();

  const renderMainRoutes = useCallback(() => {
    return (
      <Route path={RoutingConstant.HomeRoot} element={<ContentLayout></ContentLayout>}>
        <Route index path={RoutingConstant.HomeRoot} element={<Home />} />
        <Route path={RoutingConstant.Home} element={<Home />} />
        <Route path={RoutingConstant.Documents} element={<Document />} />
        {hasEquipmentLicense && <Route path={RoutingConstant.Equipment} element={<Equipment />} />}
        <Route path={RoutingConstant.Others} element={<Navigate to={''} replace />} />
      </Route>
    );
  }, [hasEquipmentLicense]);

  useEffect(() => {
    if (hasExpressEquipmentLicense()) {
      dispatch(equipmentActions.setEquipmentLicense(true));
    }
  }, []);

  return (
    <Routes>
      <Route path={RoutingConstant.HomeRoot} element={<Authentication />}>
        {renderMainRoutes()}
      </Route>
      <Route path={RoutingConstant.FriendlyAlias} element={<AuthenticationOIDC />}>
        {renderMainRoutes()}
        <Route path={RoutingConstant.Signout} element={<SignOut />} />
      </Route>
      <Route path={RoutingConstant.Preview} element={<Preview />}>
        <Route index path="" element={<HomePreview />} />
      </Route>
      <Route path={RoutingConstant.Unsubscribe} element={<Preview />}>
        <Route index path="" element={<Unsubscribe />} />
      </Route>
      <Route path={RoutingConstant.AuthCallback} element={<AuthCallback />} />
      <Route path={RoutingConstant.Logout} element={<Logout />} />
      <Route path={RoutingConstant.NotFound} element={<NotFound />} />
    </Routes>
  );
};

export default Routers;
