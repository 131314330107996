import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTracking } from '../../common/hooks/useTracking';
import Divider from '../../common/ui/Divider/Divider';
import { Icon } from '../../common/ui/Icon/Icon';
import { Modules } from '../../constants/modules.constants';
import { RoutingConstant } from '../../constants/routing.constants';
import { TrackingEvent } from '../../constants/tracking-event.constant';
import { CompanyExpressContext } from '../../pages/Layout/MainLayout';
import { equipmentSelector } from '../../states/equipment/equipmentSlice';
import { isStringNotNullOrUndefined } from '../../utils/common.utils';
import { cn } from '../../utils/css.utils';
import Alerts from '../Alerts/Alerts';
import ChangeLanguage from '../ChangeLanguage/ChangeLanguage';

type Props = {
  preview?: boolean;
};

type MenuItem = {
  id: number;
  heading: string;
  icon: string;
  handleClick: VoidFunction;
  path?: string;
};

type BottomMenuSection = {
  id: number;
  heading: string;
  items: MenuItem[];
};

const SideBar = ({ preview = false }: Props) => {
  const [translate] = useTranslation();
  const { trackEvent } = useTracking({ module: Modules.NavigationPanel });

  const navigate = useNavigate();
  const location = useLocation();
  const routeParts = location.pathname.split('/');
  let activeRoute = '';
  if (routeParts.length === 2) {
    activeRoute = RoutingConstant.HomeRoot;
  } else if (routeParts.length > 2) {
    activeRoute = routeParts[2] !== RoutingConstant.Home ? routeParts[2] : RoutingConstant.HomeRoot;
  }

  const topMenuIconStyle = 'text-lg px-3';
  const [open, setOpen] = useState(true);
  const [openChangeLanguage, setOpenChangeLanguage] = useState(false);
  const [openAlerts, setOpenAlerts] = useState(false);
  const { info } = useContext(CompanyExpressContext);
  const { companyExpressSetup } = info;
  const { hasEquipmentLicense } = useSelector(equipmentSelector);

  const renderTopMenus = useCallback(() => {
    let topMenuItems: MenuItem[] = [
      {
        id: 1,
        icon: 'Home',
        heading: translate('Menu.Home'),
        handleClick: () => {
          trackEvent(TrackingEvent.NavigationPanel.HomepageAction);
          navigate(`/${companyExpressSetup.friendlyAlias}/${RoutingConstant.HomeRoot}`);
        },
        path: RoutingConstant.HomeRoot,
      },
      {
        id: 2,
        icon: 'Documentation',
        heading: translate('CaptionResource.Caption110047'),
        handleClick: () => {
          trackEvent(TrackingEvent.NavigationPanel.DocumentsAction);
          navigate(`/${companyExpressSetup.friendlyAlias}/${RoutingConstant.Documents}`);
        },
        path: RoutingConstant.Documents,
      },
    ];
    if (hasEquipmentLicense) {
      topMenuItems.push({
        id: 3,
        icon: 'Equipment',
        heading: translate('CaptionResource.Caption1'),
        handleClick: () => {
          trackEvent(TrackingEvent.NavigationPanel.EquipmentAction);
          navigate(`/${companyExpressSetup.friendlyAlias}/${RoutingConstant.Equipment}`);
        },
        path: RoutingConstant.Equipment,
      });
    }
    return topMenuItems;
  }, [translate, hasEquipmentLicense, companyExpressSetup]);

  const bottomMenuStyle = {
    section: 'flex flex-col text-sm gap-4 px-3',
    item: 'flex items-center gap-3 rounded-sm shadow-depth4 overflow-hidden h-9',
    heading: 'text-sm font-medium text-clip whitespace-nowrap h-[20px] flex items-center mb-4',
    icon: 'text-base bg-primarybg text-primarytext w-9 h-9 p-[10px] leading-4',
  };
  const bottomMenu: BottomMenuSection[] = [
    {
      id: 1,
      heading: translate('Menu.Settings'),
      items: [
        {
          id: 1,
          icon: 'Ringer',
          heading: translate('CommonResource.Alerts'),
          handleClick: () => {
            trackEvent(TrackingEvent.NavigationPanel.AlertsAction);
            setOpenAlerts(!openAlerts);
          },
        },
        {
          id: 2,
          icon: 'LocaleLanguage',
          heading: translate('CaptionResource.ChangeLanguage'),
          handleClick: () => {
            trackEvent(TrackingEvent.NavigationPanel.ChangeLanguageAction);
            setOpenChangeLanguage(!openChangeLanguage);
          },
        },
      ],
    },
    {
      id: 2,
      heading: translate('CommonResource.FeaturedLinks'),
      items: [
        {
          id: 1,
          icon: 'Help',
          heading: translate('CommonResource.GetHelp'),
          handleClick: () => {
            trackEvent(TrackingEvent.NavigationPanel.GetHelpAction);
            window.open(translate('CaptionResource.OnixExpressGetHelpLink'));
          },
        },
        {
          id: 2,
          icon: 'ChatInviteFriend',
          heading: translate('Home.OnixCommunity'),
          handleClick: () => {
            trackEvent(TrackingEvent.NavigationPanel.OnixCommunityAction);
            window.open(translate('CaptionResource.OnixExpressCommunityLink'));
          },
        },
      ],
    },
  ];

  const renderTopMenuItem = (item: MenuItem, index: number) => {
    return (
      <div
        key={item.id}
        className={cn('flex items-center gap-3 px-2 py-3 text-sm transition ease-out', {
          'hover:cursor-pointer': !preview,
          'bg-grey30 font-medium border-l-2 border-black': item.path === activeRoute || (preview && index === 0),
        })}
        onClick={!preview ? item.handleClick : undefined}
      >
        {item.path !== RoutingConstant.Equipment && <Icon iconName={item.icon} className={topMenuIconStyle}></Icon>}
        {item.path === RoutingConstant.Equipment && <div className={cn('icon oi-equipment-type', topMenuIconStyle)}></div>}
        <span className="truncate">{item.heading}</span>
      </div>
    );
  };

  const renderBottomMenuItem = (item: MenuItem) => (
    <div
      key={item.id}
      className={cn(bottomMenuStyle.item, {
        'hover:cursor-pointer': !preview,
      })}
      onClick={!preview ? item.handleClick : undefined}
    >
      <Icon iconName={item.icon} className={bottomMenuStyle.icon} />
      <span className="truncate">{item.heading}</span>
    </div>
  );

  const navigationBannerKey = 'ExpressNavigationIframe';
  const navigationBanner = translate(`CommonResource.${navigationBannerKey}`);

  return (
    <>
      <div
        className={cn(
          'flex flex-col justify-between h-full min-w-[60px] max-w-[235px] transition-all duration-300 ease-in-out pb-4 overflow-y-auto no-scrollbar',
          {
            'w-[60px]': !open,
            'w-[229px]': open,
          }
        )}
      >
        <div>
          <div
            className={cn('flex items-center px-2 py-3', {
              'hover:cursor-pointer': !preview,
            })}
          >
            <Icon
              iconName="GlobalNavButton"
              className={cn(`${topMenuIconStyle} py-1 `, {
                'hover:bg-gray-100': !preview,
              })}
              onClick={!preview ? () => setOpen((prev) => !prev) : undefined}
            ></Icon>
          </div>
          {renderTopMenus()?.map((item, index) => renderTopMenuItem(item, index))}
        </div>
        <div className="flex flex-col gap-8">
          {open && !preview && isStringNotNullOrUndefined(navigationBanner) && navigationBanner !== `#${navigationBannerKey}` && (
            <iframe
              title="marketting-home-page"
              src={navigationBanner}
              id="marketingContent"
              scrolling="no"
              width="100%"
              height="100%"
              sandbox="allow-same-origin allow-scripts allow-popups allow-popups-to-escape-sandbox"
              className="px-3 h-[133px]"
            ></iframe>
          )}
          {bottomMenu.map((menu) => (
            <div key={menu.id} className={bottomMenuStyle.section}>
              <h4 className={cn(bottomMenuStyle.heading)}>{open ? menu.heading : <Divider />}</h4>
              {menu.items.map((item) => renderBottomMenuItem(item))}
            </div>
          ))}
        </div>
      </div>
      <div>
        {openChangeLanguage && (
          <ChangeLanguage isOpen={openChangeLanguage} onDismiss={() => setOpenChangeLanguage(!openChangeLanguage)}></ChangeLanguage>
        )}
        {openAlerts && (
          <Alerts
            isOpen={openAlerts}
            onDismiss={() => {
              setOpenAlerts(!openAlerts);
            }}
          />
        )}
      </div>
    </>
  );
};

export default SideBar;
